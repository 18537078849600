<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'600px'"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceInfoForm"
        :rules="rules"
        ref="form"
        :label-width="'150px'"
        label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }"
        size="large"
      >
        <!-- <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item prop="name" :label="'名字 ：'">
              <el-input
                v-model.trim="deviceInfoForm.name"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item prop="imgUrl" :label="'图片：'">
              <el-upload
                class="avatar-uploader"
                :action="uploadSrc"
                :headers="headers"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <el-image
                  v-if="deviceInfoForm.imgUrl"
                  :src="deviceInfoForm.imgUrl"
                  :fit="'contain'"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="close()"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{
            $t("global.cancel")
          }}</el-button>
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import base from '@/templates/add'
import Store from "@/store";
import config from "@/config";
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      titleTable: '',
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      headers: { "X-Auth-Token": Store.state.user.token },
      uploadSrc: config().uploadUrl,
      deviceInfoForm: {
        name: '',
        imgUrl: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入图片名', trigger: ['blur', 'change'] },
        ],
        imgUrl: [
          { required: true, message: '请上传图片', trigger: ['blur', 'change'] },
        ],
      },
    }
  },
  created () {
  },
  methods: {
    ...{
      show () {
        this.dialogFormVisible2 = true
        this.$nextTick((_) => {
          this.dialogFormVisible = true
        })
      },
      close () {
        this.isModelEdit = false
        this.isModelAdd = false
        this.isModelInfo = false
        this.dialogFormVisible = false
        this.resetForm()
        this.$nextTick((_) => {
          this.dialogFormVisible2 = false
        })
      },
      add () {
        this.titleTable = '新增合作伙伴图'
        this.isModelAdd = true
        this.show()
      },
      edit (data) {
        this.titleTable = '编辑合作伙伴图'
        this.isModelEdit = true
        this.deviceInfoForm = { ...data }
        this.show()
      },
      view (data) {
        this.titleTable = '查看'
        this.isModelInfo = true
        this.deviceInfoForm = { ...data }
        this.show()
      },
      // 重制
      resetForm () {
        this.deviceInfoForm = {
          name: '',
          imgUrl: ''
        }
        this.$refs.form.resetFields()
      }
    },
    handleAvatarSuccess (res, file) {
      if (res.code === '500') return this.$message.error('上传失败')
      this.deviceInfoForm.imgUrl = config().ftpUrl + '/' + res.data.newFileName;
    },
    async beforeAvatarUpload (file) {
      const isImg = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'

      if (!isImg) {
        return this.$message.error(this.$t('add.imgType'));
      }
      if (isImg) {
        this.loadingImg = true
      }
      return isImg
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let query = {
            ...this.deviceInfoForm,
          }
          this.posting = true
          try {
            await api.partner[this.isModelAdd ? 'save' : 'update'](query)
            this.posting = false
            this.$message.success(this.$t('global.submitOk'))
            this.close()
            this.$parent.onSubmit()
          } catch (error) {
            this.posting = false
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.unit-input {
  /deep/.el-form-item__content {
    display: flex;
  }
}
/deep/.el-tree__empty-block {
  display: none;
}
</style>
